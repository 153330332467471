import * as actionTypes from './actionTypes';
import axios, { setAuthToken } from '../../axios';


export const login = (user) => {
  return dispatch => {
    const token = user.api_token;
    localStorage.setItem('MenuBuilderFV_api_token', token);

    setAuthToken(token);
    
    dispatch({ type: actionTypes.UI_PRELOADER_HIDE });
    return dispatch({ type: actionTypes.LOGIN, user });
  } 
}

export const logout = () => {
  localStorage.removeItem('MenuBuilderFV_api_token');
  return dispatch => {
    dispatch({ type: actionTypes.UI_PRELOADER_HIDE });
    return dispatch({ type: actionTypes.LOGOUT });
  }
}

// async action:
export const authenticate = () => {
  return async dispatch => {
    const token = localStorage.getItem('MenuBuilderFV_api_token');

    if (token) {
      const response = await axios.get(`/user?api_token=${token}`)
      const user = response.data;

      if (user && user.id) {
        return dispatch(login(user));
      }
    }
    return dispatch(logout());
  }
}
import React, { Component } from "react";
import { Link, NavLink } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

class Sidebar extends Component {

  componentDidMount() {
    // toggle sidebar on mobile
    document.querySelector('.button-menu-mobile').addEventListener('click', e => {
      e.preventDefault();
      document.querySelector('body').classList.toggle('enlarged'); // the button (.button-menu-mobile) in Layout/Header.js
    }) 
  }

  render() {
    return (
      <div className="left side-menu">
  
        <div className="topbar-left">
          <div className="">
            <Link to="/" className="logo"><img src="/assets/images/logo-sm.png" height="90" alt="logo" /></Link>
          </div>
        </div>
  
        <div className="sidebar-inner slimscrollleft" >
          <PerfectScrollbar>
            <div id="sidebar-menu">
              <ul>
                <li className="menu-title">Main</li>
                <li>
                  <NavLink exact to="/" className="waves-effect" activeClassName="active-menu"><i className="mdi mdi-view-dashboard"></i> <span> Dashboard</span> </NavLink>
                </li>
                <li>
                  <NavLink to="/restaurants" className="waves-effect" activeClassName="active-menu"><i className="mdi mdi-home-variant"></i> <span> Restaurants</span> </NavLink>
                </li>
              </ul>
            </div>
          </PerfectScrollbar>
        </div>
  
        <div className="clearfix"></div>
  
      </div>
    );
  }
}

export default Sidebar;
